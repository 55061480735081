<template>
	<div class="whiteBack">
    <label class="mb-3">Full Body Shot</label>
    <div class="block">
		  <input type="file" ref="fileInputB" id="imageUpload" accept="image/*" @change="previewImageB" hidden>
      <button @click="chooseFile()" class="btn btn__primary mb-2 mt-2">Upload Image</button>
    </div>

    <progress :value="uploadValue" max="100" v-if="showBarB"></progress>
    <p class="caption" style="color:#EE4B2B;">*JPEG, PNG</p>
    <div>
      <button v-if="imageDataB != null" class="btn btn__primary mt-3" @click="onUploadFileB">Upload</button>
    </div>
    <div class="gallery mt-3" v-if="userProfile.bodyshot">
      <div class="item">
        <img :src="userProfile.bodyshot" alt="">
        <div class="text-center">
        <button @click.prevent="deletePic()" class="btn btn__xsmall btn__error"><i class="fas fa-trash"></i></button>
      </div>
    </div>
    </div>
	</div>
</template>

<style scoped>
  .gallery {
    display: flex;
    flex-wrap: wrap;
  }
  .item {
    display: inline-flex;
    flex-direction: column;
    position: relative;
  }
  .gallery img {
    padding:0.5rem;
    width: auto;
    height: 12rem;
  }
  .item button {
    position: absolute;
    bottom:1rem;
    right:1rem;
  }
</style>

<script>
  import { mapState } from 'vuex'
const fb = require('../../firebaseConfig.js')

export default {
	props: ['userProfile'],
  data: () => ({ 
    imageDataB: null,
    uploadValue: 0,
    showBarB:false,
  }),
  methods: {
    chooseFile() {
      document.getElementById("imageUpload").click()
    },
    deletePic() {
      this.$store.dispatch('deleteUserBodyshot', this.userProfile.id)
    },
    previewImageB(event) {
      this.uploadValue=0;
      this.imageDataB=event.target.files[0]
      if (this.imageDataB) {
      	return this.onUploadFileB()
      }
    },
    // addPics() {
    //   if (!this.userProfile.pics || this.userProfile.pics.length == 0) {
    //     fb.usersCollection.doc(this.userProfile.id).update({
    //       pics: []
    //     })
    //   }
    // },
    onUploadFileB() {
      this.showBarB = true
      let store = this.$store
      let userProfile = this.userProfile

      let pics = this.userProfile.pics

      let rand = (Math.random().toString(36).substring(2, 16) + Math.random().toString(36).substring(2, 16)).toUpperCase()
      let storageRef = fb.storageRef.child('userImages/' + rand).put(this.imageDataB);
      storageRef.on(`state_changed`, snapshot => {
        this.uploadValue=(snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error => {console.log(error.message)},
      () => {this.uploadValue=100;
        storageRef.snapshot.ref.getDownloadURL().then((downloadURL) => {

          console.log('File available at', downloadURL)

          this.userProfile.bodyshot = downloadURL
          // this.userProfile.pics.push(downloadURL)

          store.dispatch('updateUser', this.userProfile)

          // let verification = {
          //   type: 'B',
          //   url: downloadURL,
          //   verified: false,
          //   userId: userProfile.id,
          //   firstName: userProfile.firstName,
          //   lastName: userProfile.lastName,
          // }
          // store.dispatch('addVerification', verification)
          // this.verifications.push(verification)
        })
        this.showBarB = false
      })
      this.imageDataB = null
      this.$refs.fileInputB.value=null
    },
    
  },
}
</script>