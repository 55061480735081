<template>
  <div class="dashboard__container--body" v-if="(userProfile  && userProfile.id)">
    <div class="dashboard__container--body--col shiftCard">
      <ProfileImage :userProfile="userProfile" />
      
    </div>
<!--     <div class="dashboard__container--body--col shiftCard">
      <ProfileHead :userProfile="userProfile" />

    </div>
    <div class="dashboard__container--body--col shiftCard">
      <ProfileBody :userProfile="userProfile" />
    </div> -->
    <div class="dashboard__container--body--col shiftCard">
      <ProfileImageNew :userProfile="userProfile" />
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import ProfileImage from '@/components/Profile/ProfileImage.vue'
import ProfileHead from '@/components/Profile/ProfileHead.vue'
import ProfileBody from '@/components/Profile/ProfileBody.vue'
import ProfileImageNew from '@/components/Profile/ProfileImageNew.vue'

export default {
  name: 'accountPhotos',
  computed: {
    ...mapState(['userProfile', 'currentUser']),
  },
   components: {
    Loader,
    ProfileImage,
    ProfileHead,
    ProfileBody,
    ProfileImageNew
  },
}
</script>